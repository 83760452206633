<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12>
                    <v-autocomplete v-model="item.client" :items="clients" item-value="id" item-text="name" :rules="[rules.required]" :label="$t('Cliente')" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field inputmode="numeric" ref="dateCollect" :label="$t('Data')" v-model="dateCollect"
                        :rules="[rules.required, rules.rangeDate('DD/MM/YYYY', $moment(), $moment().add({ days: 7 }))]" v-mask="'##/##/####'" clearable />
                </v-flex>
                <v-flex xs6>
                    <v-text-field type="number" :label="$t('Coletas')" :rules="[rules.range(0, 100000)]" v-model="item.collect" />
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs6 class="mb-0 pb-0">
                    <v-text-field type="number" :label="$t('Seller')" :rules="[rules.range(0, 100000), maxRange(item.collect)]" v-model="item.seller" />
                </v-flex>
                <v-flex xs6 class="mb-0 pb-0">
                    <v-text-field type="number" :label="$t('PUDO')" :rules="[rules.range(0, 100000), maxRange(item.collect)]" v-model="item.pudo" />
                </v-flex>
                <v-flex xs12 class="mt-0 pt-0">
                    <v-text-field type="number" :label="$t('Coletados')" v-value="collected" disabled />
                </v-flex>
                <v-flex xs6>
                    <v-text-field type="number" :label="$t('Consolidado')" :rules="[rules.range(0, 100000), maxRange(item.collect)]" v-model="item.consolidated" />
                </v-flex>

                <v-flex xs12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">{{ $t("Salvar") }}</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },
    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                collect: 0,
                seller: 0,
                pudo: 0,
                consolidated: 0,
                enable: true,
            },
            item: { ...this.itemClean },
            clients: null,
            maxRange: (v) => (value) => Number.parseInt(value) <= Number.parseInt(v) || this.$t("Valor deve ser menor que o número de coletas."),
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    computed: {
        dateCollect: {
            get: function () {
                return this.$moment.utc(this.item.date).format("DD/MM/YYYY");
            },
            set: function (newDate) {
                if (this.$refs.dateCollect) {
                    if (this.$refs.dateCollect.validate() && newDate != null && newDate.length > 0) {
                        this.item.date = this.$moment.utc(newDate, "DD/MM/YYYY");
                    } else if (this.item && newDate == null && this.item.date != null) {
                        // this.item.date = null;
                    }
                }
            },
        },
        collected() {
            let seller = 0;
            let pudo = 0;
            if (this.item.seller > 0) {
                seller = this.item.seller;
            }
            if (this.item.pudo > 0) {
                pudo = this.item.pudo;
            }

            return Number.parseInt(seller) + Number.parseInt(pudo);
        },
    },

    methods: {
        getClients() {
            this.$http
                .get(`clients`)
                .then((result) => {
                    if (result) {
                        this.clients = result;
                    }
                })
                .catch(() => {
                    this.clients = null;
                });
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        this.getClients();
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>